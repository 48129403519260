import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faAngleUp,
  faChartPie,
  faDisplay,
  faFilm,
  faFireFlameCurved,
  faHome,
  faMinusCircle,
  faPen,
  faRankingStar,
  faSliders,
  faTrashCan
} from "@fortawesome/free-solid-svg-icons";
import {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {discoverSeries, provideImageUrl} from "../tmdb/api";
import {SearchTvSeriesEntry} from "../tmdb/types";
import {findItemById, Item, timesOf} from "../utils";
import {lookupDetails, SeriesDetails} from "../api/details";
import {ensureTimesArraySize} from "./Menu";
import Loader from "./Loader";
import "./Discover.scss";

type FilterSettings = {
  series: boolean,
  sort: FilterSort
}

enum FilterSort {
  VOTES_AVG = "vote_average.desc",
  VOTE_COUNT = "vote_count.desc",
  POPULARITY = "popularity.desc",
}

const Discover = ({items, openMenu, saveItem, removeItem}: {
  items: Item[],
  openMenu: (item: Item) => void,
  saveItem: (item: Item) => void,
  removeItem: (item: Item) => void
}) => {
  const navigate = useNavigate();

  const [filter, setFilter] = useState<FilterSettings>({series: true, sort: FilterSort.VOTE_COUNT});
  const [jumpButton, setJumpButton] = useState(false);
  const [entries, setEntries] = useState<SearchTvSeriesEntry[]>([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const listRef = useRef<any>(null);

  const checkRemainingSpace = () => {
    const bounds = listRef.current?.getBoundingClientRect();
    if (!bounds) return false;
    return bounds.bottom - window.innerHeight < 100;
  };

  // do the fetch (page change)
  const fetching = useRef(false);
  useEffect(() => {
    if (page === 0) { // reload
      setEntries([]);
      setPage(1);
      setLoading(true);
      return;
    }

    if (fetching.current) return;
    fetching.current = true;
    setLoading(true);

    console.log("DISCOVER", page);
    discoverSeries(page, filter.sort)
      .then(value => {
        setEntries(prev => [...prev, ...value.results]);
        setLoading(false);
        fetching.current = false;
      });
    // eslint-disable-next-line
  }, [page]);

  // trigger reload when filter changes
  useEffect(() => {
    setPage(0);
  }, [filter.sort, filter.series]);

  // load when empty space remaining
  useEffect(() => {
    if (!loading && checkRemainingSpace()) {
      setLoading(true);
      setPage(prev => prev + 1);
    }
  }, [loading]);

  // load on scroll
  useEffect(() => {
    const handler = () => {
      setJumpButton(window.scrollY > window.innerHeight / 2);
      if (!loading && checkRemainingSpace()) {
        setLoading(true);
        setPage(prev => prev + 1);
      }
    };
    window.addEventListener("scroll", handler);
    return () => window.removeEventListener("scroll", handler);
  }, [loading]);

  return (
    <div className={"Discover"}>
      <div className={"JumpButton" + (jumpButton ? " Visible" : "")} onClick={() => window.scroll({top: 0, behavior: "smooth"})}><FontAwesomeIcon icon={faAngleUp}/></div>

      <div className={"Head"}>
        <p className={"Title"}>Can't even remember everything?</p>
        <div className={"Subtitle"}>
          <p>Click what you watched!</p>
          <div className={"BackButton"} onClick={() => navigate("/")}><FontAwesomeIcon icon={faHome}/> back to overview</div>
        </div>
      </div>

      <div className={"Divider"}>
        <hr/>
        {/*<Filter filter={filter} setFilter={setFilter}/>*/}
      </div>

      <div ref={listRef} className={"List"}>
        {entries.map(value => ({entry: value, item: findItemById(items, value.id, true)}))
          .map(({entry, item}, index) => (
            <div className={"Item"} key={index}>
              {item && <div className={"Overlay"}>
                <p className={"Number"} onClick={() => updateTimes(item, saveItem, removeItem, entry, true)}>
                  {max(item.times)}x
                </p>
                <FontAwesomeIcon className={"Delete"} icon={max(item.times) > 1 ? faMinusCircle : faTrashCan}
                                 onClick={() => updateTimes(item, saveItem, removeItem, entry, false)}/>
                <FontAwesomeIcon className={"Edit"} icon={faPen} onClick={() => openMenu(item || {id: entry.id, series: true, times: []})}/>
              </div>}
              <img src={provideImageUrl(entry.poster_path)} alt={""} onClick={() => updateTimes(item, saveItem, removeItem, entry, true)}/>
            </div>
          ))}
      </div>

      {loading && <Loader/>}
    </div>
  );
};

const Filter = ({filter, setFilter}: {
  filter: FilterSettings,
  setFilter: (v: FilterSettings) => void
}) => {
  const [visible, setVisible] = useState(false);
  return (
    <span className={"Filter" + (visible ? " Visible" : "")}>
      <div className={"FilterButton"} onClick={() => setVisible(!visible)}><FontAwesomeIcon icon={faSliders}/> filters</div>
      <div className={"FilterMenu"}>
        {/*<div className={"Entry"}>*/}
        {/*  <p>grid size</p>*/}
        {/*  <div className={"Options"}>*/}
        {/*    <div className={"Option"}><FontAwesomeIcon icon={faTableCellsLarge}/><p>big cells</p></div>*/}
        {/*    <div className={"Option"}><FontAwesomeIcon icon={faTableCells}/><p>small cells</p></div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<hr/>*/}
        <div className={"Entry"}>
          <p>content type</p>
          <div className={"Options"}>
            <div className={"Option" + (filter.series ? " Selected" : "")}
                 onClick={() => setFilter({...filter, series: true})}><FontAwesomeIcon icon={faDisplay}/><p>tv series</p></div>
            <div className={"Option" + (!filter.series ? " Selected" : "")}
                 onClick={() => setFilter({...filter, series: false})}><FontAwesomeIcon icon={faFilm}/><p>movies</p></div>
          </div>
        </div>
        <hr/>
        <div className={"Entry"}>
          <p>sort by</p>
          <div className={"Options"}>
            <div className={"Option" + (filter.sort === FilterSort.VOTE_COUNT ? " Selected" : "")}
                 onClick={() => setFilter({...filter, sort: FilterSort.VOTE_COUNT})}><FontAwesomeIcon icon={faChartPie}/><p>most voted</p></div>
            <div className={"Option" + (filter.sort === FilterSort.POPULARITY ? " Selected" : "")}
                 onClick={() => setFilter({...filter, sort: FilterSort.POPULARITY})}><FontAwesomeIcon
              icon={faFireFlameCurved}/><p>most popular</p></div>
            <div className={"Option" + (filter.sort === FilterSort.VOTES_AVG ? " Selected" : "")}
                 onClick={() => setFilter({...filter, sort: FilterSort.VOTES_AVG})}><FontAwesomeIcon icon={faRankingStar}/><p>best rated</p></div>
          </div>
        </div>
      </div>
    </span>
  );
};

const max = (times: number[] | undefined) => {
  if (!times || times.length === 0) return 1;
  return times.map(value => timesOf(value)).reduce((prev, curr) => curr > prev ? curr : prev, 1);
};

function updateTimes(item: Item | undefined, saveItem: (item: Item) => void, removeItem: (item: Item) => void, entry: SearchTvSeriesEntry, plus: boolean) {
  if (item) {
    lookupDetails(item).then(details => {
      const series = details as SeriesDetails;
      const times = ensureTimesArraySize(item, series.seasons);
      for (let season of series.seasons) {
        const value = timesOf(times[season.number]);
        times[season.number] = Math.max(plus ? value + 1 : value - 1, 0);
      }

      if (Math.max(...times) === 0) {
        removeItem(item);
      } else {
        saveItem({...item, times: times});
      }
    });
  } else {
    saveItem({id: entry.id, times: [], series: true});
  }
}

export default Discover;